export const listOfCandidates = [
  {
      'name': 'Anna McGee',
      'imagePath': './images/mcgee.jpg',
      'recordingPath': './recordings/mcgee.m4a',
      'platformPath': './platforms/mcgee.pdf'
  },
  {
      'name': 'Kenny Peng',
      'imagePath': './images/peng.jpg',
      'recordingPath': './recordings/peng.m4a',
      'platformPath': './platforms/peng.pdf'
  },
  {
      'name': 'Emma Treadway',
      'imagePath': './images/treadway.jpg',
      'recordingPath': './recordings/treadway.m4a',
      'platformPath': './platforms/treadway.pdf'
  }
]
